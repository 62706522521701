<template>
  <b-container class="my-4">
    <b-row>
      <b-col>
        <b-card v-if="!loadingRisk">
          <b-container>
            <b-row>
              <b-col>
          <p><b-link :to="{ name: 'Risks' }">back to risks</b-link></p>
          <h2>
            <b-button class="mr-2" v-if="permissionEditTopic" variant="outline-primary" :to="{ name: 'RiskEdit', params: { id: risk.id }}">Edit</b-button>
            {{risk.name}}
          </h2>
          <h4>Definition</h4>
          <div><markdown :content="risk.comments" /></div>
          <span v-if="$config.BUILD === 'full'">
          <h4>Context</h4>
          <div><markdown :content="risk.context" /></div>
          </span>
          <!-- reg sources -->
          <span v-if="!loadingRegbites && regbites.length > 0">
          <div><strong>Regulatory source</strong></div>
                <span v-for="row in regbites" :key="row.id">
                  <strong><b-link :to="{ name: 'Regbite', params: { id: row.id }}">{{row.name}}</b-link></strong>
                  <div>
                    <markdown :content="row.comments" />
                  </div>
                </span>
          </span>

              </b-col>
            </b-row>

          </b-container>
        </b-card>

        <b-card v-if="!loadingAlerts">
          <b-container>
            <b-row>
              <b-col>
                <h2>Relevant alerts</h2>
                <span v-for="alert in alerts" :key="alert.id">
                  <h3><b-link :to="{ name: 'Alert', params: { id: alert.id }}">{{alert.name}}</b-link></h3>
                  <div>
                    <markdown :content="alert.comments" />
                  </div>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </b-card>

        <b-card v-if="!loadingNews" class="my-4">
            <b-container>
              <b-row>
                <b-col>
                  <h2>News</h2>
                  <div v-for="item in news" :key="item.id">
                    <div><strong>{{item.name}}</strong></div>
                    <div><small>{{ formatDate(item.date) }} - {{item.publisher}} - {{item.countryCode}}</small></div>
                    <div>{{item.body}}</div>
                    <div class="mb-4"><small><a :href="item.link" target="_blank">{{item.link}} <i class="fa fa-external-link" aria-hidden="true"></i></a></small></div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'

import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'Risk',
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'risk', action: 'open risk', model: 'risk', model_id: this.$route.params.id })
    this.permissionEditTopic = ac.can(this.user.acgroups).createAny('topic').granted
    this.loadRisk()
  },
  data () {
    return {
      alerts: '',
      loadingAlerts: true,
      loadingNews: true,
      loadingRegbites: true,
      loadingRisk: true,
      news: [],
      permissionEditTopic: false,
      regbites: '',
      risk: {
        name: '',
        comments: '',
        context: ''
      }
    }
  },
  methods: {
    formatDate: function (date) {
      let fdate = new Date(date)
      return fdate.toLocaleDateString()
    },
    loadRisk: async function () {
      this.$logger.debug('loadRisks started')
      this.loadingRisk = true
      try {
        let apiName = 'cosmos'
        let path = `/risk/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.risk = response
        document.title = this.risk.name
        this.loadingRisk = false
      } catch (e) {
        this.$logger.warn('/risk/:id error ', e)
      }
      if (this.$config.BUILD === 'full') {
        // get alerts
        try {
          let apiName = 'cosmos'
          let path = `/standard/relation/alert/${this.$route.params.id}/risks/risks.id/risks.del`
          let response2 = await this.$Amplify.API.get(apiName, path)
          this.$logger.debug('loadEdges OK', response2)
          this.alerts = _.sortBy(response2, ['name'])
          this.loadingAlerts = false
        } catch (e) {
          this.$logger.warn('error getting extsources', e)
        }
      }
      // get quotes
      try {
        let apiName = 'cosmos'
        let path = `/standard/relation/regbite/${this.$route.params.id}/risks/risks.id/risks.del`
        let response3 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response3)
        this.regbites = _.sortBy(response3, ['name'])
        this.loadingRegbites = false
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
      // get news
      try {
        let apiName = 'cosmos'
        let path = `/standard/relation/publication/${this.$route.params.id}/risks/risks.id/no-del`
        let response4 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('load news OK', response4)
        this.news = _.orderBy(response4, ['dateTime'], ['desc'])
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
    }
  }
}
</script>

<style scoped>

</style>
